const user_management_tbl = [
    
    { text: 'USER TYPE', value: 'role', width: "15%", sortable: false },
    { text: 'NAME', value: 'full_name', sortable: false},
    { text: 'EMAIL', value: 'email', sortable: false },
    { text: 'STATUS', value: 'status', width: "15%", sortable: false },
    { text: 'DATE REGISTERED', value: 'created_at', width: "10%", align: 'center', sortable: false },
    { text: 'ACTION', value: 'action', width: "10%", sortable: false, align: 'center' },

]

export { user_management_tbl }