<template>
  <section>
    <div class="d-flex flex-wrap align-center justify-space-between">
      <SearchBar 
        :placeholder="'Search user'" 
        :value.sync="search" 
        @clear="() => { search = '', page = 1, getUsers() }" 
        @search="() => { page = 1, getUsers() }" 
        class="mb-3"/>
      
      <section class="d-flex flex-row align-center ml-auto mb-3">
        <FilterMenu
            :initialFilter="filter"
            @resetFilters="() => { resetFilters(), page = 1, getUsers() }"
            @applyFilters="() => { page = 1, getUsers() }"
          >
          <FormLabel label="Account Type"color="primary"/>
          <v-chip-group v-model="filter.role" multiple column>
            <v-chip filter outlined label class="text-capitalize fw500" value="user">User</v-chip>
            <v-chip filter outlined label class="text-capitalize fw500" value="instructor">Instructor</v-chip>
            <v-chip filter outlined label class="text-capitalize fw500" value="admin">Admin</v-chip>
          </v-chip-group>
      
          <div v-if="tenant !== 'general_education'">
            <FormLabel label="Enrollees on:"/>
            <v-select 
              outlined 
              dense 
              class="general-custom-field roboto f14 secondary-1--text fw500"
              :items="courses"
              v-model="filter.course_id"
              item-text="title"
              item-value="id"
              hide-details="auto"
              multiple
              :disabled="filter.role.length === 1 && ['instructor', 'admin'].includes(filter.role[0])"
            >
            </v-select>
          </div>
      
          <div v-if="tenant == 'general_education'">
            <FormLabel label="Class Name"/>
            <v-select 
              outlined 
              dense 
              class="general-custom-field roboto f14 secondary-1--text fw500"
              :items="classes"
              v-model="filter.class_category_id"
              item-value="id"
              hide-details="auto"
            >
              <template slot="item" slot-scope="data">
                <v-list-item-content>
                  <v-list-item-title class="roboto f14 fw500">
                    <span class="secondary--text">{{data.item.class_code}}-</span>
                    <span class="secondary-3--text">{{data.item.class_name}}</span>
                  </v-list-item-title>
                </v-list-item-content>
              </template>
              <template slot="selection"  slot-scope="data">
                <div class="roboto f14 fw500 my-1">
                  <span class="secondary--text">{{data.item.class_code}}-</span>
                  <span class="secondary-3--text">{{data.item.class_name}} </span>
                </div>
              </template>
            </v-select>
          </div>

          <FormLabel label="Status" color="primary"/>
          <v-chip-group v-model="filter.status">
            <v-chip filter outlined label class="text-capitalize fw500" value="active">Active</v-chip>
            <v-chip filter outlined label class="text-capitalize fw500" value="inactive">Inactive</v-chip>
          </v-chip-group>
        </FilterMenu>
        
        <ButtonExport 
          @click="exportTable"
          :loading="exportLoading"
          :disabled="loading || users.length === 0"
          class="ma-1 ml-auto"/>
      </section>
    </div>
        
    <section class="text-right">
        <FormLabel :label="`${totalCount} result/s`"/>
    </section>
    <v-card>
      <v-data-table
        :headers="user_management_tbl"
        :items="users"
        :loading="loading"
        class="poppins"
        :footer-props="{
            'items-per-page-options': itemsPerPageOptions
        }"
        :page="page"
        :server-items-length="totalCount"
        @pagination="(e) => {
          page = e.page
          paginate = String(e.itemsPerPage),
          getUsers()
        }"
      >
        <template v-slot:header.name="{ header }" class="f12 poppins fw600 text-left text-uppercase secondary-2--text">
          {{ header.text }}
        </template>
        <!-- <template v-slot:item.name="{ item }">
          {{item.first_name+' '+(item.middle_name ? getMiddleInitials(item.middle_name) : '') + ' ' + item.last_name+' ' + (item.suffix ? item.suffix : '')}}
        </template> -->
        <template v-slot:item.role="{ item }" class="text-capitalize">
          {{item.role}}
        </template><template v-slot:item.status="{ item }" class="text-capitalize">
          <v-switch
            v-model="item.status"
            true-value="active"
            false-value="inactive"
            :label="item.status"
            class="text-capitalize"
            @change="updateUserStatus(item.id, $event)"
          ></v-switch>
        </template>
        <template v-slot:item.created_at="{ item }" class="text-capitalize">
          {{$dateFormat.mmDDyy(item.created_at)}}
        </template>
        <template v-slot:item.action="{ item }" class="text-capitalize">
          <section class="d-flex flex-row">
            <v-btn @click="update(item.id)" icon class="text-capitalize primary--text f12 fw600">
              <v-icon size="16" >mdi-eye-outline</v-icon>
            </v-btn>
            <!-- <v-btn @click="update(item.id)" icon class="text-capitalize primary--text f12 fw600">
              <v-icon size="16" >mdi-pencil-outline</v-icon>
            </v-btn> -->
            <v-btn v-if="item.role === 'USER'" @click="updateEnrollment(item.id)" icon class="text-capitalize primary--text f12 fw600">
              <v-icon size="16" >mdi-pencil-outline</v-icon>
            </v-btn>
            <v-btn @click="del(item.id)" icon class="text-capitalize primary--text f12 fw600">
              <v-icon size="16">mdi-trash-can-outline</v-icon>
            </v-btn>
          </section>
        </template>
      </v-data-table>
    
      <!-- <FormPagination 
        :pageCount="pageCount" 
        :page="page"
        :paginate="paginate"
        @page="(e) => { page = e, getUsers() }" 
        @paginate="(e) => { paginate = e, page = 1, getUsers() }"/> -->

    </v-card>

    <DeleteDialog 
      :dialog="deleteDialog"
      type="user"
      :loading="deleteLoading"
      @close="deleteDialog=false"
      @delete="deleteUser"
    />

    <UserModal v-if="previewDialog" :dialog="previewDialog" :type="action" :item="users.filter(item => item.id == this.id)[0]" @close="previewDialog = false"/>
    <EnrollmentModal v-if="enrollmentDialog" :dialog="enrollmentDialog" :item="users.filter(item => item.id == this.id)[0]" @close="enrollmentDialog = false"/>
  </section>
</template>

<script>
import { mapActions, mapState, mapMutations } from 'vuex'
import UserModal from '@/components/admin/user/UserModal.vue';
import EnrollmentModal from '@/components/admin/user/EnrollmentModal.vue';
import { user_management_tbl } from '@/constants/tblheaders/users'

export default {
  components:{
    UserModal,
    EnrollmentModal
  },
  data: () => ({
    user_management_tbl,
    itemsPerPageOptions: [5, 10, 20, 30, 40, 50],
    loading: false,
    deleteLoading: false,
    exportLoading: false,
    id: null,
    pageCount: 1,
    page: 1,
    paginate: '10',
    totalCount: 0,
    deleteDialog: false,
    previewDialog: false,
    enrollmentDialog: false,
    action: 'preview',
    search: '',
    filter: {
      role: [],
      class_category_id: [],
      course_id: [],
      status: null,
    }
  }),
  computed: {
    ...mapState('admin', {
      users: (state) => state.users,
      courses: (state) => state.courses,
    }),

    ...mapState({
      tenant: (state) => state.tenant
    }),

    itemsPerPage(){
      return Number(this.paginate)
    }
  },
  created() {
      this.getRegistrationTablesAction()
  },
  mounted() {
    this.getUsers()
    this.getCoursesAction()
  },
  watch: {
    filter: {
        handler(val) {
          if(val.course_id.length > 0 && !this.filter.role.includes('user')) {
            this.filter = { ...this.filter, role: ['user'] }
          }

          if(val.course_id.length > 0 && this.filter.role.some(role => ['instructor', 'admin'].includes(role))) {
            console.log('eue')
            this.filter = { ...this.filter, role: ['user'] }
          }
        },
        deep: true
    },
  },
  methods: {
    ...mapMutations(['alertMutation']),

    ...mapActions('admin', [
      'getUsersAction',
      'getRegistrationTablesAction',
      'getCoursesAction',
      'deleteUserAction',
      'updateUserStatusAction',
      'exportRegisteredUsersAction'
    ]),

    resetFilters(){
      this.filter = {
        role: [],
        class_category_id: [],
        course_id: [],
        status: null,
      }
    },

    getUsers(){
      if(!this.loading) {
        this.loading = true

        if(this.filter.role.length === 1 && ['instructor', 'admin'].includes(this.filter.role[0])) {
          this.filter.course_id = []
        }

        if(this.filter.course_id.length > 0){
          this.filter.role = ['user']
        }

        this.getUsersAction({page: this.page, paginate: Number(this.paginate), search: this.search, ...this.filter}).then(res => {
          this.loading=false
          this.pageCount = res.last_page
          this.totalCount = res.total
          this.page = res.current_page
        }).catch(() => {
          this.loading = false
          this.alertMutation({
            show: true,
            text: 'Something went wrong',
            type: "error"
          })
        })
      }
    },

    del(id) {
      this.id = id
      this.deleteDialog=true
    },

    deleteUser(){
      this.deleteLoading = true
      this.deleteUserAction(this.id).then(() => {
        this.deleteLoading = false
        this.deleteDialog = false
        this.alertMutation({
            show: true,
            text: 'User Successfully Deleted',
            type: "success"
        })
        this.getUsers()
      }).catch(() => {
        this.deleteLoading = false
        this.deleteDialog = false
        this.alertMutation({
            show: true,
            text: 'Something went wrong in deleting.',
            type: "error"
        })
      })
    },

    updateUserStatus(id, e) {
      this.updateUserStatusAction({
        user_id: id,
        status: e
      }).then(() => {
        this.alertMutation({
            show: true,
            text: `User Status Successfully Updated`,
            type: "success"
        })
        this.getUsers()
      }).catch(() => {
        this.alertMutation({
            show: true,
            text: 'Something went wrong in updating.',
            type: "error"
        })
      })
    },

    preview(e) {
      this.action='preview'
      this.id = e
      this.previewDialog = true
    },

    update(e) {
      this.action='update'
      this.id = e
      this.previewDialog = true
    },

    updateEnrollment(e) {
      this.id = e
      this.enrollmentDialog = true
    },
    
    exportTable() {
      this.exportLoading = true

      this.exportRegisteredUsersAction({search: this.search_, ...this.filter}).then(res => {
        this.exportLoading = false
        this.$exportToCSV(`Registered Users`, res)
      }).catch(() => {
        this.exportLoading = false
        this.alertMutation({
          show: true,
          text: 'Something went wrong in exporting',
          type: "error"
        })
      })
    }

  },
}
</script>